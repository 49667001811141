import { RemixBrowser, useLocation, useMatches } from '@remix-run/react';
import * as Sentry from '@sentry/remix';
import { LicenseManager } from 'ag-grid-enterprise';
import { StrictMode, startTransition, useEffect } from 'react';
import { hydrateRoot } from 'react-dom/client';
import { ProvidersTree } from './contexts/root-providers';

if (window.location.hostname !== 'localhost') {
  Sentry.init({
    dsn: 'https://7e113ecf1c859cb4df675c6718f3cfad@o4507570821201920.ingest.de.sentry.io/4507627642159184',
    tracesSampleRate: 1,
    environment: window.location.hostname === 'app.granular-energy.com' ? 'PRODUCTION' : 'STAGING',
    integrations: [
      Sentry.browserTracingIntegration({
        useEffect,
        useLocation,
        useMatches,
      }),
    ],
  });
}

LicenseManager.setLicenseKey(
  'Using_this_{AG_Grid}_Enterprise_key_{AG-059073}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Granular_Energy}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{app.granular-energy.com}_only_for_{3}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{app.granular-energy.com}_need_to_be_licensed___{app.granular-energy.com}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{25_April_2025}____[v3]_[01]_MTc0NTUzNTYwMDAwMA==4ad912496e3bb4457f8efa1579d1eb0d',
);

async function enableMSW() {
  if (import.meta.env.DEV && MSW_ENABLED === 'true') {
    const { worker } = await import('../testing/mock-api-service/browser.js');

    return worker.start({
      // disable unhandled request warnings
      onUnhandledRequest: () => {},
    });
  }

  return Promise.resolve();
}

enableMSW().then(() => {
  startTransition(() => {
    hydrateRoot(
      document,
      <StrictMode>
        <ProvidersTree>
          <RemixBrowser />
        </ProvidersTree>
      </StrictMode>,
    );
  });
});
