import type { ReactNode } from 'react';
import { useEffect } from 'react';
import { Provider } from 'urql';
import { useUrql } from '~/hooks/useUrql';

export function UrqlProvider({ children }: { children: ReactNode }) {
  const { client, initUrqlClient } = useUrql();

  useEffect(() => {
    initUrqlClient();
  }, [initUrqlClient]);

  if (!client) {
    return children;
  }

  return <Provider value={client}>{children}</Provider>;
}
